import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => {
  const isFetching = state.spread.isFetching

  return {
    isFetching,
    orderBy: "clicks",
    websites: state.spread.websites,
    stats: state.spread.stats,
    dimensions: state.spread.dimensions,
    view: "global",
  }
}

const mapDispatch = (dispatch: any) => ({
  onMount: () => {},
  onShow: () => {},
  onDownload: () => {},
  onFilter(params: { from: string; to: string }) {},
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
