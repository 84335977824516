import { actions } from "@/redux/actions"
import { RootState } from "@/redux/store"
import { connect, ConnectedProps } from "react-redux"

const mapState = (state: RootState) => ({
  websites: state.spread.websites,
  fetching: state.spread.isFetching,
  dimensions: state.spread.dimensions,
})

const mapDispatch = (dispatch: any) => ({
  goAnalytics: (id: string) => {
    dispatch(actions.ranking.$go({ id }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
