import React, { useEffect, useState } from "react"
import { connector, ContainerProps } from "./containers/Websites.container"

import { Card, CardContent, CardHeader } from "@/components/UI/card"
import { sortBy } from "lodash"
import { Area, AreaChart, XAxis, YAxis } from "recharts"

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/UI/chart"
import { RankingStatsForFrontend } from "@/entities/RankingWebsiteEntity"
import { getFavicon } from "@/utils/getFavicon"

import { Loader } from "@/components/general/Loader/Loader"
import { ChartFooter } from "@/components/shared/chart-footer/chart-footer"
import { Skeleton } from "@/components/UI/skeleton"
import dayjs from "dayjs"
import { ArrowRightIcon } from "lucide-react"
import { useIntl } from "react-intl"

const Item: React.FC<{
  id: string
  clicks: number
  impressions: number
  position: number
  click_through_rate: number
  timeline: RankingStatsForFrontend["date"]
  isLoading: boolean
  onClick: () => void
  dimensions: {
    clicks: boolean
    impressions: boolean
    position: boolean
    click_through_rate: boolean
  }
}> = (props) => {
  const favicon = getFavicon(props.id)
  const intl = useIntl()

  const chartConfig = {
    clicks: {
      label: intl.formatMessage({
        id: "analytics/histogram/filter/clicks",
      }),
    },
    impressions: {
      label: intl.formatMessage({
        id: "analytics/histogram/filter/impressions",
      }),
    },
    position: {
      label: intl.formatMessage({
        id: "analytics/histogram/filter/position",
      }),
    },
    click_through_rate: {
      label: intl.formatMessage({
        id: "analytics/histogram/filter/click_through_rate",
      }),
    },
  }

  const chartData = sortBy(props.timeline, "date").map((item) => ({
    day: item.date,
    clicks: item.clicks,
    impressions: item.impressions,
    position: item.position,
    click_through_rate: item.click_through_rate,
  }))

  return (
    <Card className="border border-slate-100 p-0">
      <CardHeader>
        <div onClick={props.onClick}>
          <div className="inline-block cursor-pointer items-center rounded p-2 font-display text-sm font-medium hover:bg-slate-100">
            <img src={favicon} className="mr-2 inline-block h-4 w-4" />
            {props.id}
            <ArrowRightIcon className="ml-1 inline-block h-4 w-4" />
          </div>
        </div>
      </CardHeader>

      <CardContent className="relative px-0 py-2">
        {props.isLoading && <Loader />}

        <ChartContainer className="aspect-[16/5]" config={chartConfig}>
          <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <XAxis
              dataKey="day"
              tickLine={false}
              axisLine={false}
              hide={true}
            />
            <YAxis
              yAxisId="clicks"
              orientation="left"
              stroke="var(--color-clicks)"
              tickLine={false}
              axisLine={false}
              hide={true}
            />
            <YAxis
              yAxisId="impressions"
              orientation="right"
              stroke="var(--color-impressions)"
              tickLine={false}
              axisLine={false}
              hide={true}
            />
            <YAxis
              yAxisId="position"
              orientation="right"
              stroke="var(--color-position)"
              tickLine={false}
              axisLine={false}
              hide={true}
            />
            <YAxis
              yAxisId="click_through_rate"
              orientation="right"
              stroke="var(--color-click_through_rate)"
              tickLine={false}
              axisLine={false}
              hide={true}
            />

            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent />}
              labelFormatter={(label) => {
                return dayjs(label).format("DD MMMM YYYY")
              }}
            />

            <Area
              yAxisId="clicks"
              dataKey="clicks"
              fillOpacity={0}
              strokeWidth={2}
              type="monotone"
              stroke="hsl(var(--chart-clicks))"
              isAnimationActive={true}
              hide={!props.dimensions.clicks}
            />
            <Area
              yAxisId="impressions"
              dataKey="impressions"
              fillOpacity={0}
              strokeWidth={2}
              type="monotone"
              isAnimationActive={true}
              stroke="hsl(var(--chart-impressions))"
              hide={!props.dimensions.impressions}
            />
            <Area
              yAxisId="position"
              dataKey="position"
              fillOpacity={0}
              strokeWidth={2}
              type="monotone"
              isAnimationActive={true}
              stroke="hsl(var(--chart-position))"
              hide={!props.dimensions.position}
            />
            <Area
              yAxisId="click_through_rate"
              dataKey="click_through_rate"
              fillOpacity={0}
              strokeWidth={2}
              type="monotone"
              stroke="hsl(var(--chart-click_through_rate))"
              isAnimationActive={true}
              hide={!props.dimensions.click_through_rate}
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>

      <ChartFooter
        clicks={props.clicks}
        impressions={props.impressions}
        position={null}
        click_through_rate={null}
      />
    </Card>
  )
}

type Props = ContainerProps

const Wrapper: React.FC<Props> = (props) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div className="relative grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
      {!isMounted ||
        (props.websites.length === 0 && props.fetching && (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ))}
      {props.websites.map((website, index) => (
        <div key={index} className="h-full w-full">
          <Item
            id={website.id}
            clicks={website.clicks}
            impressions={website.impressions}
            position={0}
            click_through_rate={0}
            timeline={website.timeline.map((item) => ({
              date: item.date,
              clicks: item.clicks,
              impressions: item.impressions,
              position: 0,
              click_through_rate: 0,
              previous_clicks: 0,
              previous_impressions: 0,
              previous_position: 0,
              previous_click_through_rate: 0,
            }))}
            onClick={() => props.goAnalytics(website.id)}
            isLoading={props.fetching}
            dimensions={props.dimensions}
          />
        </div>
      ))}
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Websites = connector(Container)
