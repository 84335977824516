import React, { useEffect } from "react"
import { connector } from "./containers/FetchSpreadOnMount.container"

export const Wrapper = (props: { onMount: () => void }) => {
  useEffect(() => {
    props.onMount()
  }, [])

  return <></>
}

export const FetchSpreadOnMount = connector(Wrapper)
