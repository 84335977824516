import {
  EyeIcon,
  ListOrdered,
  MousePointerClick,
  PercentIcon,
} from "lucide-react"
import React from "react"
import { CardFooter } from "../../UI/card"

export const ChartFooter = (props: {
  clicks: number
  impressions: number
  position: number | null
  click_through_rate: number | null
}) => {
  return (
    <CardFooter className="p-4">
      <div className="flex flex-wrap justify-between gap-4 font-display text-xs">
        <div className="flex items-center gap-1">
          <span className="text-clicks">
            <MousePointerClick className="h-4 w-4" />
          </span>
          <span className="font-medium">{props.clicks?.toLocaleString()}</span>
        </div>
        <div className="flex items-center gap-1">
          <span className="text-impressions">
            <EyeIcon className="h-4 w-4" />
          </span>
          <span className="font-medium">
            {props.impressions?.toLocaleString()}
          </span>
        </div>
        {props.position && (
          <div className="flex items-center gap-1">
            <span className="text-position">
              <ListOrdered className="h-4 w-4" />
            </span>
            <span className="font-medium">
              {props.position?.toLocaleString()}
            </span>
          </div>
        )}
        {props.click_through_rate && (
          <div className="flex items-center gap-1">
            <span className="text-click_through_rate">
              <PercentIcon className="h-4 w-4" />
            </span>
            <span className="font-medium">
              {props.click_through_rate?.toLocaleString()}
            </span>
          </div>
        )}
      </div>
    </CardFooter>
  )
}
