import { Loader } from "@/components/general/Loader/Loader"
import React from "react"
import { GlobalStats } from "../../spread/GlobalStats/GlobalStats"
import { Card } from "../../UI/card"
import { ChartContent } from "./chart-content"
import { connector, ContainerProps } from "./containers/chart.container"

const Wrapper: React.FC<ContainerProps> = (props) => {
  return (
    <Card className="relative border border-slate-100 shadow-slate-100 transition-shadow ease-in-out">
      {props.isFetching && <Loader />}

      <div className="p-4">
        <GlobalStats />
      </div>

      <div className="mt-4" />

      <ChartContent />
    </Card>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Chart = connector(Container)
