import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => {
  const stats = state.spread.stats.global

  return {
    stats,
    selected: "clicks",
    isFetching: state.spread.isFetching,
    dimensions: state.spread.dimensions,
    type: "global",
  }
}

const mapDispatch = (dispatch: any) => ({
  onClick: (
    type: "clicks" | "impressions" | "position" | "click_through_rate"
  ) => {
    dispatch(actions.spread.ToggleDimension({ type }))
    // dispatch(actions.analytics.$RankingStoreOrderBy(type))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
